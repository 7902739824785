<template>
  <div>
    <b-card>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        사이트 관리자 설정
      </h4>
      <hr>
      <b-table
        responsive
        show-empty
        hover
        empty-text="No matching records found"
        :items="newRowData"
        :fields="fields1"
        style="text-align: center !important; font-size: small;"
      >
        <template #cell(userid)="data">
          <b-form-input
            v-model:value="newRowData[data.index].userid"
            class=""
            size="sm"
          />
        </template>
        <template #cell(authSite)="data">
          <b-form-input
            v-model:value="newRowData[data.index].authSite"
            class=""
            size="sm"
          />
        </template>
        <template #cell(memo)="data">
          <b-form-input
            v-model:value="newRowData[data.index].memo"
            class=""
            size="sm"
          />
        </template>
        <template
          #cell(edit)="data"
        >
          <div
            class="editClass"
            @click.stop
          >
            <span class="d-inline-flex">
              <div style="min-width: 9rem;">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1"
                  size="sm"
                  variant="outline-success"
                  style="font-size: smaller"
                  @click="copyRow(data.item)"
                >
                  복사
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  style="font-size: smaller;"
                  @click="deleteRowRegiste(data.item)"
                >
                  삭제
                </b-button>
              </div>
            </span>
          </div>
        </template>
      </b-table>
      <hr>
      <!-- 등록  -->
      <div class="text-center pt-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          size="sm"
          @click="submit('createManySetSiteAdmin')"
        >
          <feather-icon
            icon="CheckCircleIcon"
            size="12"
          />
          관리자등록
        </b-button>
      </div>
    </b-card>
    <!-- 팝업 모달 -->
    <!-- 수동 등록 경기 조회-->
    <b-card>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        사이트 관리자 권한 목록
      </h4>
      <div class="m-2">
        <!-- Per Page -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="3"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!-- Table -->
      <b-table
        responsive
        show-empty
        hover
        small
        empty-text="No matching records found"
        :per-page="perPage"
        :items="fetchSetSiteAdmin"
        :fields="fields2"
        :filter="filter"
        :filter-included-fields="filterOn"
        style="text-align: center !important; font-size: small"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :current-page="currentPage"
        @filtered="onFiltered"
      >
        <template
          #cell(no)="data"
          size="sm"
          style="text-align: center; min-width: 3rem"
        >
          {{ data.index + 1 }}
        </template>
        <template #cell(userid)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 10rem"
          >
            {{ data.item.userid }}
          </div>
        </template>
        <template #cell(authSite)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 4rem"
          >
            {{ data.item.authSite }}
          </div>
        </template>
        <template #cell(enrollId)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 8rem"
          >
            {{ data.item.enrollId }}
          </div>
        </template>
        <template #cell(memo)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 8rem"
          >
            {{ data.item.memo }}
          </div>
        </template>
        <template #cell(createdAt)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 8rem"
          >
            {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
          </div>
        </template>
        <template #cell(updatedAt)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 8rem"
          >
            {{ data.item.updatedAt | formatDate('YYYY-MM-DD HH:mm') }}
          </div>
        </template>
        <template
          #cell(edit)="data"
        >
          <div
            class="editClass"
            @click.stop
          >
            <span class="d-inline-flex">
              <div style="min-width: 8rem;">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  style="font-size: smaller;"
                  type="submit"
                  @click="submit('deleteSetSiteAdmin', data.item)"
                >
                  삭제
                </b-button>
              </div>
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!--    <div v-for="(item, index) in newRowData" :key="index">-->
    <!--      <div v-for="(value, key) in item" :key="key">-->
    <!--        <li><strong>{{ key }}:</strong> <i class="text-success">{{ value }}</i></li>-->
    <!--      </div>-->
    <!--      <br>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTr,
  BTh,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  VBTooltip,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'

import {
  FETCH_SET_SITE_ADMIN,
} from '@/store/settings/action'
import {
  CREATEMANY_SET_SITE_ADMIN,
  UPDATEMANY_SET_SITE_ADMIN,
  DELETE_SET_SITE_ADMIN,
} from '@/store/settings/mutation'

import flatPickr from 'vue-flatpickr-component'

import * as moment from 'moment-timezone'
import Swal from 'sweetalert2'
import {number} from "echarts/lib/export";

const settingsStore = createNamespacedHelpers('settingsStore')
moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTr,
    BTh,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BPagination,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    VBTooltip,
    flatPickr,
    BModal,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    flatPickr,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      socket: null,
      perPage: 15,
      pageOptions: [15, 30, 50],
      sortBy: 'userid',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 1,
      fields1: [
        {
          key: 'userid', sortable: false, label: '아이디', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'authSite', sortable: false, label: '권한사이트1', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'authSite', sortable: false, label: '권한사이트2', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'memo', sortable: false, label: '메모', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'edit', sortable: false, label: '설정', thClass: 'col3',
        },
      ],
      fields2: [
        {
          key: 'no', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'userid', sortable: false, label: '아이디', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'authSite', sortable: false, label: '권한사이트', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'enrollId', sortable: false, label: '등록자', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'memo', sortable: false, label: '메모', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'createdAt', sortable: false, label: '등록일', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'updatedAt', sortable: false, label: '변경일', thClass: 'col1', tdClass: 'inner-sm-padding',
        },
        {
          key: 'edit', sortable: false, label: '설정', thClass: 'col3',
        },
      ],
      newRowData: [
        {
          userid: '',
          authSite: '',
          memo: '',
        },
      ],
      filterByToday: true,
    }
  },
  computed: {
    ...settingsStore.mapGetters({
      fetchSetSiteAdmin: 'fetchSetSiteAdmin',
    }),
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...settingsStore.mapActions({
      $fetchSetSiteAdmin: FETCH_SET_SITE_ADMIN,
      $createManySetSiteAdmin: CREATEMANY_SET_SITE_ADMIN,
      $updateManySetSiteAdmin: UPDATEMANY_SET_SITE_ADMIN,
      $deleteSetSiteAdmin: DELETE_SET_SITE_ADMIN,
    }),
    updateParam(t, e, v, i) {
      const events = {
        t, e, v, i,
      }
      this.$store.commit('settingsStore/updateManyParam', events)
    },
    async fetchData() {
      await this.$fetchSetSiteAdmin({
      })
      this.totalRows = this.fetchSetSiteAdmin.length
    },
    async submit(target, rowData) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, rowData)
          // console.log(result.isConfirmed)
          // console.log(target)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, rowData) {
      try {
        const createRowData = this.newRowData.map(row => ({
          ...row,
          enrollId: this.userData.userid,
        }))
        if (target === 'createManySetSiteAdmin') {
          await this.$createManySetSiteAdmin(createRowData)
        }
        // if (target === 'updateManySetSiteAdmin') {
        //   await this.$updateManySetSiteAdmin({
        //     idx: rowData.idx,
        //   })
        // }
        // console.log('rowData.idx:::', rowData.idx)
        if (target === 'deleteSetSiteAdmin') {
          await this.$deleteSetSiteAdmin({
            idx: Number(rowData.idx),
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // 행 복사
    copyRow(row) {
      // row를 복사하여 테이블 데이터에 추가
      this.newRowData.push({ ...row })
      // this.newRowData = {...row}
    },
    // 행 삭제
    deleteRowRegiste(row) {
      // 테이블 데이터에서 해당 행 제거
      const index = this.newRowData.indexOf(row)
      if (index !== 0) {
        this.newRowData.splice(index, 1)
      }
    },
    showToast(title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          variant: 'info',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
.inner-sm-padding {
  padding: 5px !important; /* 원하는 간격 설정 */
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
